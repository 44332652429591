import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import 'vue3-carousel/dist/carousel.css';
import 'animate.css'
import VueGtag from "vue-gtag";

const app = createApp(App);
app.config.globalProperties.apiUrl = "https://admin.viniciusfariasadv.com.br";
app.use(router).mount('#app')
app.use(VueGtag, {
    appName: 'Vinicius Farias Site',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
        return {
          page_title: 'PaginaX',
        page_path: to.path
        }
    },
    config: { 
        id: "G-QM3ZEVYZ4J",
    },
}, router);