<template>
  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Dúvidas Frequentes
        </h2>
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-gray-900">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-justify text-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
      
    </div>
    <a href="/contato" class="inline-flex items-center mb-12 px-4 py-2  border border-transparent text-base font-medium rounded-xl text-white bg-blue-500 hover:bg-blue-600 transform transition duration-1000 hover:scale-90 cursor-pointer">
    Entrar em contato
    </a>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

const faqs = [
  {
    question: "Existe algum benefício para quem nunca pagou INSS?",
    answer:
      "Para quem não nunca contribuiu com a Previdência, a melhor alternativa seria o BPC-LOAs, um benefício no valor de 1 salário mínimo pago à pessoa idosa com mais de 65 anos ou deficiente. Mas para ter direito a esse benefício, é preciso que a pessoa seja considerada de baixa-renda.",
  },
  {
    question: "Quanto tempo dura um processo na Justiça?",
    answer:
      "A resposta a essa pergunta depende de inúmeros fatores, como o tipo de ação e o local onde ela foi proposta. Uma ação de concessão de auxílio-doença, por exemplo, em regra não necessita de audiência, ao contrário do que ocorre com os casos de aposentadoria rural e pensão por morte.",
  },
  {
    question: "Por que o INSS nega tantos benefícios?",
    answer:
      "O INSS apenas segue o que está na Lei, que muitas vezes é bastante criteriosa. O Juiz, por outro lado, pode formar seu convencimento com base em diversos outros elementos além da Lei, e isso permite que um caso negado na via administrativa possa ser examinado com um outro olhar pela Justiça.",
  },
  {
    question: "Sou obrigado a contratar um advogado para ir à Justiça?",
    answer:
      "Não. Quando surge a necessidade de se procurar a Justiça, a pessoa pode protocolar seu processo até mesmo sozinha. Se preferir, pode ainda se socorrer à Defensoria Pública. Mas acreditamos que o acompanhamento feito por um profissional da confiança com certeza aumenta a chance de êxito no processo.",
  },
  
]

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  setup() {
    return {
      faqs,
    }
  },
}
</script>