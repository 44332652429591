<template>
<Navbar/>
  <ServicosCard/>
  <Faq/>
  <Footer/>
</template>

<script>

import Navbar from "../components/Navbar.vue"
import Footer from "../components/Footer.vue"
import Faq from "../components/Servicos/Faq.vue"
import ServicosCard from "../components/Servicos/ServicosCard.vue"

export default {
    components: {
        Navbar,
        Faq,
        Footer,
        ServicosCard
    },
  setup() {
    return {

    }
  },
}
</script>