<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <nav class="border-t mt-12 border-gray-200 px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <a href="#" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">      
      <a :href="`/blogs?page=${i}`" class="hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium" v-for="i in lastPage" :class="currentPage == i?'border-indigo-500 text-indigo-600':''" :key="i">
        {{ i }}
      </a>
    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a href="#" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
        
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  
  props: {
    lastPage:{
      type: Number,
    },
    currentPage:{
      type: Number
    }
  },
  mounted(){
    console.log(this.lastPage);
  }
}
</script>