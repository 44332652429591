<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="grid grid-cols-1 mt-12 gap-4 sm:grid-cols-2 pb-12">
    <div v-for="person in people" :key="person.email" class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:bg-blue-200 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 transform transition duration-1000 hover:scale-90 cursor-pointer">
      <div class="flex-shrink-0">
      
      </div>
      <div class="flex-1 min-w-0 h-10">
        <a href="/servicos" class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true" />
          
          <p class="text-lg mt-2 text-gray-500 font-bold truncate">
            {{ person.role }}
          </p>
        </a>
      </div>
    </div>
    
  </div>
  <a href="/servicos" class="inline-flex items-center mb-12 px-4 py-2  border border-transparent text-base font-medium rounded-xl text-white bg-blue-500 hover:bg-blue-600 transform transition duration-1000 hover:scale-90 cursor-pointer">
                        Saber mais
            </a>
</template>

<script>
const people = [
  {
    name: 'Vinicius Farias',
    role: 'Aposentadorias',
  },
  {
    name: 'Vinicius Farias',
    role: 'Auxílio-Doença',
  },
  {
    name: 'Vinicius Farias',
    role: 'Pensão por morte',
  },
  {
    name: 'Vinicius Farias',
    role: 'Benefícios negados pelo INSS',
  },
  // More people...
]

export default {
  setup() {
    return {
      people,
    }
  },
}
</script>