<template>
    <div class="bg-white">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="animate__animated animate__fadeInDown sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Nossos Serviços</h1>
        <p class="mt-5 text-xl text-gray-500 sm:text-center">Estamos preparados para atender com especialidade os seguintes serviços.</p>
        
        <div class="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
          <button type="button" class="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Especialistas em</button>
        </div>
      </div>
      <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        <div v-for="tier in tiers" :key="tier.name" class="animate__animated animate__fadeIn border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 transform transition duration-1000 hover:scale-90 cursor-pointer">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">{{ tier.name }}</h2>
            <p class="mt-4 text-sm text-gray-500">{{ tier.description }}</p>
            
            <a href="/contato" class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 transform transition duration-1000 hover:scale-90 cursor-pointer">
                Entrar em contato
            </a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-extrabold text-gray-900 tracking-wide uppercase">Tipos</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li v-for="feature in tier.includedFeatures" :key="feature" class="flex space-x-3 text-left">
                <CheckIcon class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                <span class="font-bold text-gray-500">{{ feature }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/solid'
const tiers = [
  {
    name: 'Concessão de benefícios do INSS',
    description: 'Ajudamos você a solicitar os seus benefícios.',
    includedFeatures: ['Aposentadoria, Auxílio-doença.', 'Benefício de Prestação Continuada – BPC.' , 'Pensão por morte.', 'Salário-Maternidade, Auxílio-Reclusão', 'Demais benefícios'],
  },
  {
    name: 'Benefícios negados pelo INSS',
    description: 'Todos os seus benefícios negados pelo INSS',
    includedFeatures: [
      'Consultoria e medidas administrativas. ',
      'Consultoria e medidas judicias.',
      'Benefícios negados.',
    ],
  },
  {
    name: 'Processo Previdenciário',
    description: 'Procedimento administrativo ou judicial envolvendo o INSS.',
    includedFeatures: [
      'Equipe completa, experiente e qualificada em Processo Administrativo e Processo Judicial Previdenciário. ',
      'Suporte ao cliente do início ao fim.',
      'Equipe com casos especiais.',
    ],
  },
  {
    name: 'Tudo que envolve a Previdência Social',
    description: 'Todo o ecossistema previdenciário',
    includedFeatures: [
      'Especialistas em Direito Previdenciário. ',
      'Tornar o processo simples',
      'Rápido e acessível.',
    ],
  },
]
export default {
    components: {
        CheckIcon
    },
    setup() {
        return {
            tiers
        }
    },
}
</script>