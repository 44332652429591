<template>
<div class="w-full bg-blue-900" style="background-image: url('https://blog.kuriertecnologia.com.br/wp-content/uploads/2018/07/211447-entenda-como-melhorar-a-gestao-de-pessoas-no-escritorio-de-advocacia.jpg'); background-position: center center; background-blend-mode: multiply; background-size: cover;">
		<div class="container flex flex-col flex-wrap content-center justify-center p-4 py-20 mx-auto md:p-24">
			<h1 class="text-5xl antialiased font-semibold leading-none text-center text-white">Processo simples, rápido e acessível</h1>
			<p class="pt-2 pb-8 text-xl antialiased text-center text-white">Tenha qualidade e compromisso no seu processo</p>
			<div class="flex flex-row justify-center">
				<a href="/contato" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50 transform transition duration-1000 hover:scale-90 cursor-pointer">
                    Entrar em contato
        </a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>