<template>
  <div class="py-5 bg-gray-200">
    <div class="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
      <p class="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-5 mt-10">Depoimentos</p>
      <div v-if="slides == null">
        <div class="flex flex-col text-center items-center max-w-sm px-8 py-6 mx-auto">
          <img :src="require('@/assets/spinner.gif')" width="100" />
        </div>
      </div>
      <carousel v-else :items-to-show="isMobile() ? 1 : 2" :wrapAround="true" :autoplay="4000">
        <slide v-for="(item, index) in slides" :key="index">
          <div class="carousel__item flex items-center justify-center px-5 py-5">
            <div class="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 shadow-lg px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
              <div class="w-full mb-10">
                <div class="text-3xl text-indigo-500 text-left leading-tight h-3">“</div>
                <p class="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
                  {{ item.message }}
                </p>
                <div class="text-3xl text-indigo-500 text-right leading-tight h-3 -mt-3">”</div>
              </div>
              <div class="w-full">
                <p class="text-md text-indigo-500 font-bold text-center">{{ item.name }}</p>
              </div>
            </div>
          </div>
        </slide>
        <template #addons class="mt-10">
          <navigation class="mt-10" />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    isMobile() {
      return window.innerWidth <= 760;
    },
    getDepoimentos() {
      axios
        .get(`${this.apiUrl}/api/depoimentos`)
        .then((response) => {
          this.slides = response.data;

          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDepoimentos();
  },
  data() {
    return {
      slides: null,
    };
  },
};
</script>
<style scoped>
.carousel__pagination {
  margin-top: 20px;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>
<style>
.carousel__prev,
.carousel__next {
  border: 5px solid white;
  background-color: rgba(17, 24, 39, 1);
}
.carousel__pagination-button {
  background-color: gray;
}

.carousel__pagination-button--active {
  background-color: rgba(17, 24, 39, 1);
}
</style>
