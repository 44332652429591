<template>
    <Popover as="header" class="fi">
        <div class="bg-gray-900 pt-6 pb-5">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="/">
                  <span class="sr-only">Workflow</span>
                  <img class="h-8 w-auto sm:h-10" src="../assets/logo1.png" alt="" />
                </a>
                <div class="-mr-2 flex items-center md:hidden">
                  <PopoverButton class="bg-white-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-whtie-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span class="sr-only">Abrir menu</span>
                    <MenuIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="hidden space-x-8 md:flex md:ml-10">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-base font-medium text-white hover:text-gray-300">{{ item.name }}</a>
              </div>
            </div>
            <div class="hidden md:flex md:items-center md:space-x-6">
              <a href="/contato" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50 transform transition duration-1000 hover:scale-90 cursor-pointer">
                    Entrar em contato
                    <ExternalLinkIcon class="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </nav>
        </div>

        <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="relative top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
            <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img class="h-8 w-auto" src="../assets/vficon.png" alt="" />
                </div>
                <div class="-mr-2">
                  <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                    <span class="sr-only">Fechar menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="pt-5 pb-6">
                <div class="px-2 space-y-1">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" class="block px-3 py-2 rounded-md text-base font-extrabold text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
                </div>
                <div class="mt-6 px-5">
                  <a href="/contato" class="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700">Contato</a>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline'
import {ExternalLinkIcon } from '@heroicons/vue/solid'
const navigation = [
  { name: 'Inicio', href: '/' },
  { name: 'Quem somos', href: '/quem-somos' },
  { name: 'Serviços', href: '/servicos' },
  { name: 'Benefícios INSS', href: '/beneficios-inss' },
  { name: 'Blog', href: '/blogs' },
]
export default {
    components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
    ExternalLinkIcon
  },
    setup() {
        return {
            navigation,
        
        }
    },
}
</script>