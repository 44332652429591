import { createRouter, createWebHistory } from 'vue-router'
import Main from '../views/Main.vue'
import Contato from '../views/Contato.vue'
import QuemSomos from '../views/QuemSomos.vue'
import Servicos from '../views/Servicos.vue'
import BeneficiosINSS from '../views/BeneficiosINSS.vue'
import ArtigoPage from '../components/Blog/ArtigoPage.vue'
import BlogPage from '../components/Blog/BlogPage.vue'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Main
  },
  {
    path: '/contato',
    name: 'Contato',
    component: Contato
  },
  {
    path: '/quem-somos',
    name: 'Quem Somos',
    component: QuemSomos
  },
  {
    path: '/servicos',
    name: 'Serviços',
    component: Servicos
  },
  {
    path: '/beneficios-inss',
    name: 'Benefícios do INSS',
    component: BeneficiosINSS
  },
  {
    path: '/artigo',
    name: 'Artigo',
    component: ArtigoPage
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: BlogPage,
    props: true
  },
  {
    path: '/blog/:id',
    name: 'Blog',
    component: ArtigoPage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${ process.env.VUE_APP_TITLE } | ${ to.name }`
  next()
})

export default router
