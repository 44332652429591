<template>
  <div class="bg-white">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
        <div class="animate__animated animate__fadeInDown space-y-5 sm:space-y-4">
          <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Nosso time</h2>
          <p class="text-xl text-gray-500">Somos profissionais capacitados e prontos para atender todos os nossos clientes.</p>
          <img class="shadow-lg rounded-lg" src="../../assets/logo2.jpg" alt="" />
        </div>
        <div class="lg:col-span-2">
          <ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
            <li v-for="person in people" :key="person.name">
              <div class="space-y-6">
                <div class="animate__animated animate__fadeInRight aspect-w-3 aspect-h-2">
                  <img class="object-cover shadow-lg rounded-lg" :src="person.image" alt="" />
                </div>
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3>{{ person.name }}</h3>
                  <p class="text-indigo-600">{{ person.role }}</p>
                </div>
                <div class="text-lg">
                  <p class="text-gray-500" v-html="person.bio"/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      people: null,
    };
  },
  methods: {
    getPeoples() {
      axios
        .get(`${this.apiUrl}/api/funcionarios`)
        .then((response) => {
          this.people = response.data;
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPeoples();
  },
};
</script>
