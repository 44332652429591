<template>
  <div class="hidden w-4/12 -mx-8 lg:block">
    <div class="px-8">
      <h1 class="mb-4 text-xl font-bold text-gray-700">Categorias</h1>
      <div class="flex flex-col max-w-sm px-4 py-6 mx-auto bg-white rounded-lg shadow-md">
          
        <ul>
          <li v-for="(item, ind) in categories" :key="ind">
            <a :href="`/blogs?category_id=${item.id}`" class="mx-1 font-bold text-gray-700 hover:text-gray-600 hover:underline">{{ item.title }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="px-8 mt-10">
      <h1 class="mb-4 text-xl font-bold text-gray-700">Última publicação</h1>
      <div v-if="last == null">
        <div class="flex flex-col text-center items-center max-w-sm px-8 py-6 mx-auto bg-white rounded-lg shadow-md">
          <img :src="require('@/assets/spinner.gif')" width="100" />
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col max-w-sm px-8 py-6 mx-auto bg-white rounded-lg shadow-md">
          <div class="flex items-center justify-center">
            <a :href="last.category.href" class="px-2 py-1 text-sm text-green-100 bg-gray-600 rounded hover:bg-gray-500"> {{ last.category.name }}</a>
          </div>
          <div class="mt-4">
            <a :href="`/blog/${last.id}`" class="text-lg font-medium text-gray-700 hover:underline">{{ last.title }}</a>
          </div>
          <div class="items-center justify-between mt-4">
            <div class="flex justify-between w-full">
              <img :src="last.author.imageUrl" alt="avatar" class="object-cover w-8 h-8 rounded-full" /><a href="#" class="mx-3 text-sm text-gray-700 hover:underline">{{ last.author.name }}</a>
            </div>
            <p class="text-sm font-light text-gray-600">{{ last.date }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    last: {
      type: Object,
    },
    categories: {
      type: Array,
    },
  },
  mounted(){
      console.log(this.categories);
  }
};
</script>

<style></style>
