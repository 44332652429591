<template>
  <Navbar />

  <!-- component -->
  <div class="overflow-x-hidden bg-gray-100">
    <div class="animate__animated animate__fadeInDown text-center mt-20">
      <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Nosso Blog</h2>
      <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">Aqui você encontra todas as publicações disponíveis no nosso portal de informações.</p>
    </div>

    <div class="px-6 py-8">
      <div class="container flex justify-between mx-auto">
        <div class="w-full lg:w-8/12">
          <h1 class="ml-16 mb-4 text-xl font-bold text-gray-700 text-left">Publicações</h1>
          <div v-if="posts == null">
            <div class="flex flex-col text-center items-center max-w-sm px-8 py-6 mx-auto">
              <img :src="require('@/assets/spinner.gif')" width="100" />
            </div>
          </div>
          <!-- Blog item -->
          <div class="mt-6" v-for="(post, indx) in posts" :key="indx">
            <div class="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md text-left">
              <div class="flex items-center justify-between">
                <span class="font-light text-gray-600">{{ post.date }}</span
                ><a :href="post.category.href" class="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">{{ post.category.name }}</a>
              </div>
              <div class="mt-2">
                <a :href="`/blog/${post.id}`" class="text-2xl font-bold text-gray-700 hover:underline">{{ post.title }}</a>
                <p class="mt-2 text-gray-600">
                  {{ post.description }}
                </p>
              </div>
              <div class="flex items-center justify-between mt-4">
                <a :href="`/blog/${post.id}`" class="text-blue-500 hover:underline">Ler mais</a>
                <div>
                  <a href="" class="flex items-center"
                    ><img :src="post.author.imageUrl" alt="avatar" class="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block" />
                    <h1 class="font-bold text-gray-700 hover:underline">{{ post.author.name }}</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- BLOG ITEM -->

          <pagination-component :lastPage="pagination.last_page || 0" :currentPage="pagination.current_page || 0" />
        </div>
        <RightSide :last="lastPost" :categories="categories" />
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from "../Navbar.vue";
import Footer from "../Footer.vue";
import RightSide from "./RightSide.vue";
import axios from "axios";
import PaginationComponent from "./PaginationComponent.vue";

export default {
  components: {
    RightSide,
    Navbar,
    Footer,
    PaginationComponent,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      posts: null,
      pagination: {
        last_page: 0,
        current_page: 0,
      },
      categories: [],
      lastPost: null,
      params: "",
      filterParams: {
        category_id: null,
        order_by: "asc",
        search: "",
        page: 0,
      },
    };
  },
  methods: {
    getBlogs() {
      axios
        .get(`${this.apiUrl}/api/blogs${this.params}`)
        .then((response) => {
          var data = response.data;
          this.posts = data.blogs.data;
          this.pagination = data.blogs.pagination;
          this.categories = data.categories;
          this.lastPost = data.last;
          console.log(this.pagination);
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var path = this.$route.fullPath;
    this.params = path.replace("/blogs", "");

    this.getBlogs();
  },
};
</script>
