<template>
  <Navbar />

  <main class="overflow-hidden">
    <!-- Header -->
    <div class="bg-warm-gray-50">
      <div class="py-24 lg:py-32">
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <h1 class="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">Página de Contato</h1>
        </div>
      </div>
    </div>

    <!-- Contact section -->
    <section class="relative bg-white" aria-labelledby="contact-heading">
      <div class="absolute w-full h-1/2 bg-warm-gray-50" aria-hidden="true" />
      <!-- Decorative dot pattern -->
      <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          class="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
          width="404"
          height="384"
          fill="none"
          viewBox="0 0 404 384"
          aria-hidden="true"
        >
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-warm-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative bg-white shadow-xl">
          <h2 id="contact-heading" class="sr-only">Contact us</h2>

          <div class="grid grid-cols-1 lg:grid-cols-3">
            <!-- Contact information -->
            <div class="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-blue-500 to-blue-600 sm:px-10 xl:p-12">
              <!-- Decorative angle backgrounds -->
              <div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                <svg class="absolute inset-0 w-full h-full" width="343" height="388" viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                  <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
                  <defs>
                    <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#fff" />
                      <stop offset="1" stop-color="#fff" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              
              <h3 class="text-lg font-medium font-extrabold text-white">Formulário para contato</h3>
              <p class="mt-6 text-base text-white max-w-3xl">
                Somos especialistas na defesa de segurados da Previdência Social (INSS) e também dos Regimes Próprios de Previdência. Temos um time completo de advogados que atuam desde o requerimento
                administrativo até o pedido judicial, caso seja necessário.
              </p>
              <dl class="mt-8 space-y-6">
                <dt><span class="sr-only">Número</span></dt>
                <dd class="flex text-base text-teal-50">
                  <PhoneIcon class="flex-shrink-0 w-6 h-6 text-teal-200" aria-hidden="true" />
                  <span class="ml-3 text-white">(98) 98136-6320</span>
                </dd>
                <dt><span class="sr-only">Email</span></dt>
                <dd class="flex text-base text-teal-50">
                  <MailIcon class="flex-shrink-0 w-6 h-6 text-teal-200" aria-hidden="true" />
                  <span class="ml-3 text-white">viniciusfeitosafarias@gmail.com</span>
                </dd>
              </dl>
              <ul role="list" class="mt-8 flex space-x-12">
                <li>
                  <a class="flex text-teal-200 hover:text-teal-100" target="_blank" href="https://wa.me/+5598981366320">
                    <span class="sr-only">Whatsapp</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-white" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                      />
                    </svg>
                    <span class="ml-3 text-white">Clique para entrar em contato</span>
                  </a>
                </li>
              </ul>
            </div>

            <!-- Contact form -->
            <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 class="text-lg font-medium text-warm-gray-900">O que podemos fazer por você?</h3>
              <h5 class="text-xs font-medium text-warm-gray-900">Sua mensagem será encaminhada para nosso Whatsapp</h5>
              <form action="#" method="POST" class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label for="first-name" class="block text-sm font-medium text-warm-gray-900 text-left">Seu nome</label>
                  <div class="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autocomplete="given-name"
                      v-model="formData.nome"
                      class="py-3 px-4 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    />
                  </div>
                </div>
                
                <div>
                  <div class="flex justify-between">
                    <label for="phone" class="block text-sm font-medium text-warm-gray-900">Whatsapp</label>
                  </div>
                  <div class="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autocomplete="tel"
                      v-model="formData.phone"
                      class="py-3 px-4 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    />
                  </div>
                </div>                
                <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label for="message" class="block text-sm font-medium text-warm-gray-900">Mensagem</label>
                    <span id="message-max" class="text-sm text-warm-gray-500">Max. 500 caracteres</span>
                  </div>
                  <div class="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      v-model="formData.mensagem"
                      class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-warm-gray-300 rounded-md"
                      aria-describedby="message-max"
                    />
                  </div>
                </div>
                <div class="w-screen max-w-lg bg-green-200 mx-auto mt-6 p-2" v-if="sucesso">
                  <div class="flex space-x-2">
                    <svg class="w-6 h-6 stroke-green-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <p class="text-green-900 font-semibold">Mensagem enviada!</p>
                  </div>
                  <p class="ml-8 text-left text-green-800">Obrigado por entrar em contato, assim que possível enviaremos uma resposta.</p>
                </div>
                <div class="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="button"
                    @click="submit"
                    class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
                  >
                    {{ enviando? 'Enviando...':'Enviar'}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact grid -->
    <section aria-labelledby="offices-heading">
      <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h2 id="offices-heading" class="text-3xl font-extrabold text-warm-gray-900">Nosso Escritório</h2>
        <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
          <div v-for="office in offices" :key="office.id">
            <iframe
              class="relative h-64 mt-12 mx-auto pl-4 pr-8 sm:px-6 lg:px-8"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15944.060167762873!2d-44.2914084!3d-2.501927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x980062d843218ce8!2sVin%C3%ADcius%20Farias%20Advocacia!5e0!3m2!1spt-BR!2sbr!4v1649241702627!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <LocationMarkerIcon class="relative h-12 mt-12 mx-auto pl-4 pr-8 sm:px-6 lg:px-8" aria-hidden="true" />
            <h3 class="text-lg font-medium text-warm-gray-900">{{ office.city }}</h3>
            <p class="mt-2 text-base text-warm-gray-500">
              <span v-for="line in office.address" :key="line" class="block">{{ line }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>

  <Footer />
</template>

<script>
import { MailIcon, PhoneIcon, LocationMarkerIcon } from "@heroicons/vue/outline";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  components: {
    LocationMarkerIcon,
    MailIcon,
    PhoneIcon,
    Navbar,
    Footer,
  },
  data() {
    return {
      enviando:false,
      sucesso: false,
      offices: [{ id: 1, city: "São Luís - MA", address: ["Avenida Colares Moreira", "Edifício Planta Tower, sala 802","Jardim Renascença", "65075-441"] }],
      formData: {
        nome: "",
        email: "",
        phone: "",
        estado: "",
        mensagem: "",
      },
    };
  },
  methods: {
    submit() {
      this.enviando = true;
      axios
        .post(`${this.apiUrl}/api/contato`, this.formData)
        .then((response) => {
          if (response.data.error != 0) {
            alert("Erro ao enviar contato.");
            return;
          }
          this.sendToWhatsapp(this.formData);

          this.formData = {
            nome: "",
            email: "",
            phone: "",
            estado: "",
            mensagem: "",
          };
          this.sucesso = true;
          this.enviando = false;
        })
        .catch((error) => {
          console.log(error);
          this.enviando = false;
        });
    },
    sendToWhatsapp(formData){
      let texto = `Olá, estou entrando em contato através do site.
          %0aNome: ${formData.nome}
          %0aMensagem:%0a${formData.mensagem}`;
          window.open(`https://api.whatsapp.com/send/?phone=+5598981366320&text=${texto}`, "_blank") 
    }
  },
};
</script>
