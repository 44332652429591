<template>
    <Navabr/>

    <GridBeneficios/>
    <Blog/>
    <Footer/>
</template>

<script>
import Navabr from "../components/Navbar.vue"
import Footer from "../components/Footer.vue"
import GridBeneficios from "../components/Beneficios/GridBeneficios.vue"
import Blog from "../components/MainPage/Blog.vue"

export default {
    components: {
        Navabr,
        Footer,
        GridBeneficios,
        Blog
    },
    setup() {
        
    },
}
</script>