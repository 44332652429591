<template>
  <Navbar />

  <div v-if="publicacoes == null">
    <div class="flex flex-col text-center items-center max-w-sm px-8 py-6 mx-auto">
      <img :src="require('@/assets/spinner.gif')" width="100" />
    </div>
  </div>
  <div v-else class="relative bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-screen-lg mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="text-lg ">
      <div class="relative -mx-4 top-0 overflow-hidden">
        <img
          class="top-0 inset-0 object-cover object-top w-full h-56 bg-cover filter blur -mt-5"
          :src="publicacoes.imageUrl"
          alt=""
        />
      </div>

      <div class="-mt-20 w-full max-w-7xl mx-auto">
        <div class="relative rounded-2xl">
          <img
            class="w-1/3 mx-auto bg-cover rounded-md"
            :src="publicacoes.imageUrl"
            alt=""
          />
        </div>
      </div>
      <div class="relative px-4 sm:px-6 lg:px-8 max-w-screen-lg mx-auto mt-10">
        <h1 class="text-3xl font-bold">{{ publicacoes.title }}</h1>
        <h1 class="text-2xl font-bold">{{ publicacoes.description }}</h1>        
        <!-- <h1>
          <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">{{ publicacoes.tema }}</span>
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{ publicacoes.titulo }}</span>
        </h1> -->        
      </div>
      <article v-html="publicacoes.text" class="max-w-screen-lg mx-auto my-20">
        
      </article>  
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from "../../components/Navbar.vue";
import Footer from "../../components/Footer.vue";
import axios from "axios";

// const publicacoes = {
//   tema: 'Tema do artigo',
//   titulo: 'Titulo do artigo',
//   paragrafo1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
//   paragrafo2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
// }
export default {
  components: {
    Navbar,
    Footer,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      publicacoes: null
    };
  },
  methods: {
    getBlogs() {
      axios
        .get(`${this.apiUrl}/api/blogs/${this.id}`)
        .then((response) => {
          var postData = response.data;
          this.publicacoes = postData;
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getBlogs();
  },
};
</script>
<style>
.pt-\[17\%\] {
  padding-top: 17%;
}
.mt-\[-10\%\] {
  margin-top: -10%;
}
.pt-\[56\.25\%\] {
  padding-top: 65%;
}
</style>
