<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">
      <Navbar/>
      <main>
        <div class="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden fundo-principal">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                
              </div>
              <div class="animate__animated animate__fadeInRight mt-12 -mb-16 sm:-mb-48 lg:m-12 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                 <div class="lg:py-12">
                  
                  <h1 class="animate__animated animate__fadeInDown mt-4 text-2xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span class="block">Time completo</span>
                    <span class="pb-3 block bg-clip-text text-transparent text-white sm:pb-5">de advogados especialistas</span>
                  </h1>
                  <p class="animate__animated animate__fadeInUp mt-5 text-base text-white sm:text-xl lg:text-lg xl:text-xl">
                    Há 10 anos ajudamos quem precisa de algum benefício da Previdência. Sempre buscando uma solução rápida, nossa equipe presta um suporte completo do início ao fim do processo.
                  </p>
                  <a href="/contato" class="mt-5 inline-flex items-center px-4 py-2  border border-transparent text-base font-medium rounded-xl text-white bg-blue-500 hover:bg-blue-600 transform transition duration-1000 hover:scale-90 cursor-pointer">
                        Entre em contato <ChevronRightIcon class="ml-2 w-5 h-5 text-white" aria-hidden="true" />
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SERVIÇOS -->
        <div class="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
          <div class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
            <div>
              <h2 class="text-base font-semibold tracking-wider text-cyan-600 uppercase">Nossas marcas</h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Atuamos no processo administrativo e judicial
              </p>
              <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                  Conheça algum dos nossos serviços.
              </p>
            </div>
            <ServicosGrid/> 
          </div>
        </div>

        <!-- GRID DE COISAS BOAS DO VINICIUS FARIAS ADVOCACIA -->
        <CaracGrid/>

        <!-- Feedback section -->
        <Feedback/>

        <!-- Blog section -->
        <Blog/>

        <!-- CTA Section -->
        <CTA/>
        
        <Testimonials />
      </main>
        <Footer/>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue"
import ServicosGrid from "../components/MainPage/ServicosGrid.vue"
import CaracGrid from "../components/MainPage/CaracGrid.vue"
import Feedback from "../components/MainPage/Feedback.vue"
import Testimonials from "../components/MainPage/Testimonials.vue"
import Blog from "../components/MainPage/Blog.vue"
import CTA from "../components/MainPage/CTA.vue"
import Footer from "../components/Footer.vue"


import { ChevronRightIcon } from '@heroicons/vue/solid'

const navigation = [
  { name: 'Inicio', href: '#' },
  { name: 'Quem somos', href: '#' },
  { name: 'Serviços', href: '#' },
  { name: 'Benefícios INSS', href: '#' },
  { name: 'Blog', href: '#' },
]

export default {
  components: {
    ChevronRightIcon,
    Navbar,
    ServicosGrid,
    CaracGrid,
    Feedback,
    Blog,
    CTA,
    Footer,
    Testimonials
  },
  setup() {
    return {
      navigation,
    }
  },
}
</script>