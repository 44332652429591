<template>
<Navbar/>
  <!-- Componente Time de Advogados -->
  <Advogados/>

    <a href="/servicos" class="inline-flex items-center mb-12 px-4 py-2  border border-transparent text-base font-medium rounded-xl text-white bg-blue-500 hover:bg-blue-600 transform transition duration-1000 hover:scale-90 cursor-pointer">
    Conheça nossos serviços
    </a>

  <!-- Componente Cta -->
  <CTA2/>
  <Testimonials />
  <!-- Componente Footer -->
  <Footer/>
</template>

<script>
import Navbar from "../components/Navbar.vue"
import CTA2 from "../components/QuemSomos/CTA2.vue"
import Advogados from "../components/QuemSomos/Advogados.vue"
import Footer from "../components/Footer.vue"
import Testimonials from "../components/MainPage/Testimonials.vue"
export default {
    components:{
        Navbar,
        CTA2,
        Advogados,
        Footer,
        Testimonials
    },
    setup() {
        return {

        }
    },
}
</script>