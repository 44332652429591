<template>
<div class="bg-gray-900">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div class="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div class="lg:self-center">
            <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
              <span class="block"> Está precisando de ajuda?</span>
            </h2>
            <p class="mt-4 text-lg leading-6 text-indigo-200"> Entre em contato conosco, estamos esperando a sua decisão. O vídeo ao lado contém dicas para o seu planejamento previdenciário.  </p>
            <a href="/contato" class="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50">Entrar em contato</a>
          </div>
        </div>
        <div class="-mt-2 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/00F4FrOVvUM?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {
        
    },
    setup() {
        
    },
}
</script>