<template>
    <div class="relative bg-white py-16 sm:py-24 lg:py-32">
          <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 class="text-base font-semibold tracking-wider text-cyan-600 uppercase">Serviço Especial</h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Tenha um excelente atendimento e resultado com sua consulta
            </p>
            
            <div class="mt-12">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div v-for="feature in features" :key="feature.name" class="pt-6">
                  <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-gradient-to-r from-blue-500 to-blue-600 rounded-md shadow-lg">
                          <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">{{ feature.name }}</h3>
                      <p class="mt-5 text-base text-gray-500">
                        {{ feature.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import {
  CloudUploadIcon,
  LockClosedIcon,
  RefreshIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/outline'
const features = [
  {
    name: 'Confiança',
    description: 'A solidez de um trabalho feito com compromisso e com ética.',
    icon: CloudUploadIcon,
  },
  {
    name: 'Suporte',
    description: 'Atendimento completo e humanizado a cada cliente.',
    icon: LockClosedIcon,
  },
  {
    name: 'Dedicação',
    description: 'Aqui a gente ama o que faz e daremos o melhor resultado possível.',
    icon: RefreshIcon,
  },
  {
    name: 'Foco',
    description: 'Somos especialistas.',
    icon: ShieldCheckIcon,
  },
]

export default {
    components: {

    },
    setup(){
        return {
            features,
        }
    }
}
</script>